<template>
    <painel titulo="Chamados Não Atendidos" icone="pi pi-phone" :refreshFunction="obterTodosNaoAtendidos">
        <div class="container text-center mt-5 mb-5">
            <div class="table-responsive my-5">
                <tabela
                    headerStyle="width: 3em"
                    :stateKey="idTabela"
                    :salvarEstado="false"
                    :data="chamadosNaoAtendidos"
                    :mostrarExportar="true"
                    :exportFilename="fileName(true)"
                >
                    <template #conteudo>
                        <Column headerStyle="width: 70px">
                            <template #body="slotProps">
                                <btn-visualizar
                                    title="Visualizar Chamado"
                                    class="flex align-items-center justify-content-center"
                                    @click="visualizarChamado(slotProps.data)"
                                ></btn-visualizar>
                            </template>
                        </Column>

                        <Column header="E-mail Usuário" field="createdBy" :sortable="true"></Column>
                        <Column header="Tipo Chamado" field="tipoChamado" :sortable="true">
                            <template #body="slotProps">
                                {{
                                    slotProps.data.tipoChamado == 'CONSULTORIA_EXPREESS'
                                        ? 'Consultoria Express'
                                        : slotProps.data.tipoChamado == 'PRODUTO_REAL'
                                        ? 'Produto Real'
                                        : 'Curadoria'
                                }}
                            </template>
                        </Column>
                        <Column header="Data Criação" field="created" :sortable="true">
                            <template #body="slotProps">
                                {{ $dateFormat(slotProps.data.created, 'DD/MM/YYYY HH:mm:ss') }}
                            </template>
                        </Column>
                        <Column header="Data Atendimento" field="modified" :sortable="true">
                            <template #body="slotProps">
                                {{ $dateFormat(slotProps.data.modified, 'DD/MM/YYYY HH:mm:ss') }}
                            </template>
                        </Column>

                        <Column header="Status">
                            <template #body="slotProps">
                                <Tag :severity="formatarTag(slotProps.data)">{{ formatar(slotProps.data) }}</Tag>
                            </template>
                        </Column>
                    </template>
                </tabela>
            </div>
        </div>
    </painel>
    <painel titulo="Chamados Atendidos" icone="pi pi-user" :refreshFunction="obterTodosAtendidos">
        <div class="container text-center mt-5 mb-5">
            <div class="table-responsive my-5">
                <tabela
                    headerStyle="width: 3em"
                    :stateKey="idTabela"
                    :salvarEstado="false"
                    :data="chamadosAtendidos"
                    :mostrarExportar="true"
                    :exportFilename="fileName(false)"
                >
                    <template #conteudo>
                        <Column headerStyle="width: 70px">
                            <template #body="slotProps">
                                <btn-visualizar
                                    title="Visualizar Chamado"
                                    class="flex align-items-center justify-content-center"
                                    @click="visualizarChamado(slotProps.data)"
                                ></btn-visualizar>
                            </template>
                        </Column>

                        <Column header="E-mail Usuário" field="createdBy" :sortable="true"></Column>
                        <Column header="Tipo Chamado" field="tipoChamado" :sortable="true">
                            <template #body="slotProps">
                                {{
                                    slotProps.data.tipoChamado == 'CONSULTORIA_EXPREESS'
                                        ? 'Consultoria Express'
                                        : slotProps.data.tipoChamado == 'PRODUTO_REAL'
                                        ? 'Produto Real'
                                        : 'Curadoria'
                                }}
                            </template>
                        </Column>
                        <Column header="Data Criação" field="created" :sortable="true">
                            <template #body="slotProps">
                                {{ $dateFormat(slotProps.data.created, 'DD/MM/YYYY HH:mm:ss') }}
                            </template>
                        </Column>
                        <Column header="Data Atendimento" field="modified" :sortable="true">
                            <template #body="slotProps">
                                {{ $dateFormat(slotProps.data.modified, 'DD/MM/YYYY HH:mm:ss') }}
                            </template>
                        </Column>

                        <Column header="Status">
                            <template #body="slotProps">
                                <Tag :severity="formatarTag(slotProps.data)">{{ formatar(slotProps.data) }}</Tag>
                            </template>
                        </Column>
                    </template>
                </tabela>
            </div>
        </div>
    </painel>
    <Dialog v-model:visible="modal" modal header="Chamado" :style="{ width: '55vw' }">
        <Fieldset class="mb-5">
            <template #legend>
                <div class="flex align-items-center text-primary">
                    <span class="pi pi-phone"></span>
                </div>
            </template>

            <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-12">
                    <label for="emailSolicitante">E-mail do Solicitante</label>
                    <InputText id="emailSolicitante" optionLabel="nome" class="font-bold" v-model="chamado.createdBy" disabled></InputText>
                </div>

                <div class="field col-12 md:col-6">
                    <label for="tipoChamado">Tipo do Chamado</label>
                    <InputText id="tipoChamado" optionLabel="nome" class="font-bold" v-model="chamado.tipoChamado" disabled></InputText>
                </div>

                <div class="field col-12 md:col-6">
                    <label for="dataSolicitacao">Data da Solicitação</label>
                    <InputText id="dataSolicitacao" optionLabel="nome" class="font-bold" v-model="chamado.dataCriacao" disabled> </InputText>
                </div>

                <div class="field col-12 md:col-4">
                    <label for="emailSolicitante">Atendente</label>
                    <InputText id="emailSolicitante" optionLabel="nome" class="font-bold" v-model="chamado.modifiedBy" disabled></InputText>
                </div>

                <div class="field col-12 md:col-4">
                    <label for="dataAtendimento">Data do Atendimento</label>
                    <InputText id="dataAtendimento" optionLabel="nome" class="font-bold" v-model="chamado.dataAtendimento" disabled> </InputText>
                </div>

                <div class="field col-12 md:col-3">
                    <p for="status">Status</p>
                    <span id="status" optionLabel="nome" class="font-bold font-bold p-component"
                        ><Tag :severity="formatarTag(chamado)">{{ formatar(chamado) }}</Tag></span
                    >
                </div>
            </div>
        </Fieldset>
        <Fieldset v-if="chamado.tipoChamado == 'CONSULTORIA_EXPREESS'">
            <template #legend>
                <div class="flex align-items-center text-primary">
                    <span class="pi pi-user"></span>
                </div>
            </template>
            <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-6">
                    <label for="nome">Nome</label>
                    <InputText id="nome" class="font-bold" v-model="chamado.origem.consultoriaExpress.nome" disabled />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="cpf">CPF</label>
                    <InputText id="cpf" class="font-bold" v-model="chamado.origem.consultoriaExpress.cpf" disabled />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="ni">CNPJ, DAP, NIRF, SICAB</label>
                    <InputText id="ni" class="font-bold" v-model="chamado.origem.consultoriaExpress.ni" disabled />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="razaoSocial">Nome do Representante Legal</label>
                    <InputText id="razaoSocial" class="font-bold" v-model="chamado.origem.consultoriaExpress.razaoSocial" disabled />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="cidade">Município</label>
                    <InputText id="cidade" class="font-bold" v-model="chamado.origem.consultoriaExpress.cidade" disabled />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="cep">CEP (Solicitante)</label>
                    <InputText id="cep" class="font-bold" v-model="chamado.origem.consultoriaExpress.cep" disabled />
                </div>
                <div class="field col-12 md:col-12">
                    <label for="endereco">Endereço comercial</label>
                    <InputText id="endereco" class="font-bold" v-model="chamado.origem.consultoriaExpress.endereco" disabled />
                </div>
                <div class="field col-12 md:col-12">
                    <label for="endereco">Unidade Regional</label>
                    <InputText id="endereco" class="font-bold" v-model="chamado.origem.consultoriaExpress.unidadeRegional" disabled />
                </div>
                <div class="field col-12 md:col-12">
                    <label for="tema">Serviço Solicitado</label>
                    <InputText id="tema" class="font-bold" v-model="chamado.origem.consultoriaExpress.tema" disabled />
                </div>
            </div>
        </Fieldset>
        <Fieldset v-if="chamado.tipoChamado == 'PRODUTO_REAL'">
            <template #legend>
                <div class="flex align-items-center text-primary">
                    <span class="pi pi-shopping-cart"> </span>
                </div>
            </template>
            <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-6">
                    <label for="email">E-mail (Gestor do projeto)</label>
                    <InputText id="email" type="email" class="font-bold" v-model="chamado.origem.solicitacaoProdutoReal.emailGestor" disabled />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="gestor">Nome do Gestor do Projeto</label>
                    <InputText id="gestor" class="font-bold" v-model="chamado.origem.solicitacaoProdutoReal.gestor" disabled></InputText>
                </div>
                <div class="field col-12 md:col-12">
                    <label for="evento">Nome do Evento</label>
                    <InputText id="nome" optionLabel="nome" class="font-bold" v-model="chamado.origem.solicitacaoProdutoReal.eventoNome" disabled></InputText>
                </div>
                <div class="field col-12 md:col-3">
                    <label for="dataInicio">Data início do evento</label>
                    <InputText
                        id="dataInicio"
                        optionLabel="nome"
                        class="font-bold"
                        v-model="chamado.origem.solicitacaoProdutoReal.dataInicio"
                        disabled
                    ></InputText>
                </div>
                <div class="field col-12 md:col-3">
                    <label for="dataFim">Data fim do evento</label>
                    <InputText id="dataFim" class="font-bold" v-model="chamado.origem.solicitacaoProdutoReal.dataFim" disabled></InputText>
                </div>
                <div class="field col-12 md:col-3">
                    <label for="horario">Horário do evento</label>
                    <InputText id="horario" class="font-bold" v-model="chamado.origem.solicitacaoProdutoReal.horario" disabled></InputText>
                </div>
                <div class="field col-12 md:col-3">
                    <label for="preco">Preço</label>
                    <InputText inputId="preco" class="font-bold" v-model="chamado.origem.solicitacaoProdutoReal.preco" disabled />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="projeto">Projeto</label>
                    <InputText id="projeto" class="font-bold" v-model="chamado.origem.solicitacaoProdutoReal.projeto" disabled></InputText>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="material">Material didático</label>
                    <InputText id="material" class="font-bold" v-model="chamado.origem.solicitacaoProdutoReal.material" disabled></InputText>
                </div>
                <div class="field col-12 md:col-12">
                    <label for="unidade">Unidade do SEBRAE</label>
                    <InputText id="unidade" class="font-bold" v-model="chamado.origem.solicitacaoProdutoReal.unidade" disabled></InputText>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="contrato">Contrato</label>
                    <InputText id="contrato" class="font-bold" v-model="chamado.origem.solicitacaoProdutoReal.contrato" disabled></InputText>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="modalidade">Modalidade do Evento</label>
                    <InputText id="evento" class="font-bold" v-model="chamado.origem.solicitacaoProdutoReal.modalidade" disabled></InputText>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="evento">Tipo do evento</label>
                    <InputText id="evento" class="font-bold" v-model="chamado.origem.solicitacaoProdutoReal.evento" disabled></InputText>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="tema">Tema Principal</label>
                    <InputText id="tema" class="font-bold" v-model="chamado.origem.solicitacaoProdutoReal.temas" disabled></InputText>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="complexidadeSelecionada">Complexidade da solução</label>
                    <InputText id="complexidadeSelecionada" class="font-bold" v-model="chamado.origem.solicitacaoProdutoReal.complexidade" disabled></InputText>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="carga">Carga Horária</label>
                    <InputText id="carga" class="font-bold" v-model="chamado.origem.solicitacaoProdutoReal.complexidade" disabled></InputText>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="geraCertificado">Irá gerar certificado</label>
                    <InputText id="geraCertificado" class="font-bold" v-model="chamado.origem.solicitacaoProdutoReal.geraCertificado" disabled></InputText>
                </div>
                <div class="field col-12 md:col-12">
                    <label for="compexidade">Descrição do Argumento de venda</label>
                    <Textarea rows="5" class="font-bold" v-model="chamado.origem.solicitacaoProdutoReal.argumentoVenda" disabled />
                </div>
            </div>
        </Fieldset>
        <Fieldset v-if="chamado.tipoChamado == 'CURADORIA'">
            <template #legend>
                <div class="flex align-items-center text-primary">
                    <span class="pi pi-shopping-cart"> </span>
                </div>
            </template>
            <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-12">
                    <label for="nomeGestor">Nome do Gestor</label>
                    <InputText id="nomeGestor" class="font-bold" v-model="chamado.origem.curadoria.nomeGestor" disabled />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="unidade">Unidade</label>
                    <InputText id="unidade" class="font-bold" v-model="chamado.origem.curadoria.unidade" disabled />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="necessidade">Necessidade</label>
                    <InputText id="necessidade" class="font-bold" v-model="chamado.origem.curadoria.necessidade" disabled />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="temas">Temáticas</label>
                    <InputText id="temas" class="font-bold" v-model="chamado.origem.curadoria.temas" disabled />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="publicoAlvo">Público Alvo</label>
                    <InputText id="publicoAlvo" class="font-bold" v-model="chamado.origem.curadoria.publicoAlvo" disabled />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="instrumentoProduto">Instrumento do Produto</label>
                    <InputText id="instrumentoProduto" class="font-bold" v-model="chamado.origem.curadoria.instrumentoProduto" disabled />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="tipoPagamento">Tipo de Pagamento</label>
                    <InputText id="tipoPagamento" class="font-bold" v-model="chamado.origem.curadoria.tipoPagamento" disabled />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="prazo">Prazo</label>
                    <InputText id="prazo" class="font-bold" v-model="chamado.origem.curadoria.prazo" disabled />
                </div>
                <div class="field col-12 md:col-6">
                    <label for="tipoProduto">Formato do Produto</label>
                    <InputText id="tipoProduto" class="font-bold" v-model="chamado.origem.curadoria.tipoProduto" disabled />
                </div>
                <div class="field col-12 md:col-12">
                    <label for="descricaoObjetivo">Objetivos e necessidades</label>
                    <Textarea rows="5" class="font-bold" v-model="chamado.origem.curadoria.descricaoObjetivo" disabled />
                </div>
                <div class="field col-12 md:col-12" v-if="chamado.origem.curadoria.descricaoObjetivoKahoot">
                    <label for="descricaoObjetivoKahoot">Objetivo ação kahoot</label>
                    <Textarea rows="5" class="font-bold" v-model="chamado.origem.curadoria.descricaoObjetivoKahoot" disabled />
                </div>
                <div class="field col-12 md:col-12">
                    <label for="informacaoAdicional">Informação adicional</label>
                    <Textarea rows="5" class="font-bold" v-model="chamado.origem.curadoria.informacaoAdicional" disabled />
                </div>
            </div>
        </Fieldset>
        <template #footer>
            <Button label="Fechar" icon="pi pi-times" class="p-button-text" @click="fecharModal()" />
            <Button
                label="Nova Solução"
                icon="pi pi-pencil"
                @click="novaSolucao(chamado)"
                v-if="chamado.tipoChamado == 'PRODUTO_REAL' && !chamado.modifiedBy"
                style="background-color: #6c757d; border-color: #6c757d"
            />
            <Button label="Atender Chamado" icon="pi pi-check" class="p-button" @click="atenderChamado(chamado)" v-if="!chamado.modifiedBy" />
        </template>
    </Dialog>
    <router-view></router-view>
</template>

<script>
import BtnVisualizar from '../components/BtnVisualizar.vue';
import Services from './service';

export default {
    components: { BtnVisualizar },
    name: 'Chamados',
    data() {
        return {
            idTabela: 'Chamados',
            inativos: true,
            data: [],
            modal: false,
            chamado: {
                id: '',
                origemId: '',
                ipUsuario: '',
                tipoChamado: '',
                createdBy: '',
                modifiedBy: '',
                active: '',
                created: '',
                modified: '',
                origem: {
                    solicitacaoProdutoReal: {
                        emailGestor: '',
                        gestor: '',
                        evento: '',
                        dataInicio: '',
                        dataFim: '',
                        preco: '',
                        horario: '',
                        projeto: '',
                        material: '',
                        eventoNome: '',
                        modalidade: '',
                        temas: '',
                        complexidade: '',
                        argumentoVenda: '',
                        geraCertificado: '',
                    },
                    consultoriaExpress: {
                        ni: '',
                        cep: '',
                        cpf: '',
                        nome: '',
                        email: '',
                        telefone: '',
                        razaoSocial: '',
                        tema: '',
                        dataNascimento: '',
                        cidade: '',
                        endereco: '',
                        unidadeRegional: '',
                    },
                    curadoria: {
                        emailSolicitante: '',
                        nomeSolicitante: '',
                        nomeGestor: '',
                        unidade: '',
                        necessidade: '',
                        temas: '',
                        publicoAlvo: '',
                        instrumentoProduto: '',
                        tipoPagamento: '',
                        tipoProduto: '',
                        descricaoObjetivo: '',
                        descricaoObjetivoKahoot: '',
                        informacaoAdicional: '',
                        prazo: '',
                    },
                },
            },
            chamadosNaoAtendidos: [],
            chamadosAtendidos: [],
            tag: '',
        };
    },
    mounted() {
        this.obterTodos();
    },
    computed: {
        mostrarListagem() {
            return this.$route.name === 'Atendimento Chamado';
        },
    },
    beforeRouteUpdate() {
        this.obterTodos();
    },
    methods: {
        fileName(obj) {
            const currentDate = new Date();
            const day = String(currentDate.getDate()).padStart(2, '0');
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const year = currentDate.getFullYear();

            const prefix = obj ? 'Chamados_Nao_Atendidos' : 'Chamados_Atendidos';

            return `${prefix}_${day}-${month}-${year}`;
        },
        visualizarChamado(data) {
            this.chamado = data;
            this.$store.dispatch('addRequest');
            Services.obterOrigemPorChamado(data).then((response) => {
                if (response?.success) {
                    this.chamado.origem = response.data;
                    this.chamado.dataCriacao = this.$dateFormat(this.chamado.created, 'DD/MM/YYYY HH:mm:ss');
                    this.chamado.dataAtendimento = this.$dateFormat(this.chamado.modified, 'DD/MM/YYYY HH:mm:ss');

                    if (this.chamado.origem.solicitacaoProdutoReal) {
                        this.chamado.origem.solicitacaoProdutoReal.dataInicio = this.$dateFormat(
                            this.chamado.origem.solicitacaoProdutoReal.dataInicio,
                            'DD/MM/YYYY'
                        );
                        this.chamado.origem.solicitacaoProdutoReal.dataFim = this.$dateFormat(this.chamado.origem.solicitacaoProdutoReal.dataFim, 'DD/MM/YYYY');
                        this.chamado.origem.solicitacaoProdutoReal.preco = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                            this.chamado.origem.solicitacaoProdutoReal.preco
                        );
                        this.chamado.origem.solicitacaoProdutoReal.geraCertificado = this.chamado.origem.solicitacaoProdutoReal.geraCertificado ? 'Sim' : 'Não';
                    }

                    if (this.chamado.origem.consultoriaExpress) {
                        this.chamado.origem.consultoriaExpress.dataNascimento = this.$dateFormat(
                            this.chamado.origem.consultoriaExpress.dataNascimento,
                            'DD/MM/YYYY'
                        );
                    }

                    if (this.chamado.origem.curadoria) {
                        this.chamado.origem.curadoria.prazo = this.$dateFormat(this.chamado.origem.curadoria.prazo, 'DD/MM/YYYY');
                    }

                    this.modal = true;
                }

                this.$store.dispatch('removeRequest');
            });
        },
        obterTodos() {
            this.obterTodosNaoAtendidos();
            this.obterTodosAtendidos();
        },
        obterTodosNaoAtendidos() {
            this.$store.dispatch('addRequest');
            Services.obterTodos(true).then((response) => {
                if (response?.success) {
                    this.chamadosNaoAtendidos = response.data;
                }

                this.$store.dispatch('removeRequest');
            });
        },
        obterTodosAtendidos() {
            this.$store.dispatch('addRequest');
            Services.obterTodos(false).then((response) => {
                if (response?.success) {
                    this.chamadosAtendidos = response.data;
                }

                this.$store.dispatch('removeRequest');
            });
        },

        novaSolucao(chamado) {
            this.$router.push({ name: 'Solucao_Inserir', params: { chamado: JSON.stringify(chamado) } });
            this.fecharModal();
        },

        atenderChamado(chamado) {
            Services.atenderChamado(chamado.id).then((response) => {
                if (response?.success) {
                    this.obterTodos();
                    this.fecharModal();
                    this.$message({
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: 'Chamado atendido com sucesso!',
                    });
                }
            });
        },
        fecharModal() {
            this.modal = false;
            this.limparChamado();
        },
        limparChamado() {
            this.chamado = {
                id: '',
                origemId: '',
                ipUsuario: '',
                tipoChamado: '',
                createdBy: '',
                modifiedBy: '',
                active: '',
                created: '',
                modified: '',
                origem: {
                    solicitacaoProdutoReal: {
                        emailGestor: '',
                        gestor: '',
                        evento: '',
                        dataInicio: '',
                        dataFim: '',
                        preco: '',
                        horario: '',
                        projeto: '',
                        material: '',
                        eventoNome: '',
                        modalidade: '',
                        temas: '',
                        complexidade: '',
                        argumentoVenda: '',
                        geraCertificado: '',
                    },
                },
            };
        },
        mostrarInativos(inativo) {
            this.inativos = inativo;
        },
        formatar(data) {
            return data.active == true ? 'Aguardando Atendimento' : 'Atendido';
        },
        formatarTag(data) {
            return data.active == true ? (this.tag = 'warning') : (this.tag = 'info');
        },
    },
    watch: {
        inativos() {
            this.obterTodos();
        },
    },
};
</script>
