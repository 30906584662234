<template>
    <Button icon="pi pi-search" class="p-button-text" @click="toVisualizar()"></Button>
</template>

<script>
export default {
    props: {
        objeto: {
            type: Object,
        },
    },

    methods: {
        toVisualizar() {
            this.$emit('onVisualizar', this.objeto);
        },
    },
};
</script>