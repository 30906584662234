import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_SOL}${process.env.VUE_APP_API_PATH}/sol`;

const urlBase = `${api}/chamados`;

export default {
    obterTodos(active) {
        active == null ? active = '' : active
        return axiosJwt.get(`${urlBase}?active=${active}`);
    },
    obterOrigemPorChamado(chamado) {
        return axiosJwt.post(`${urlBase}/origem`, chamado);
    },
    atenderChamado(id) {
        return axiosJwt.put(`${urlBase}/atender/${id}`);
    }
};
